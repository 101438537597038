import { useMemo, useState } from 'react';
import { format, isEqual } from 'date-fns';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { ShortCompanyLocation } from '@durma-soft/gros-sdk/dist/types/company';
import {
  useAuthUserCompany,
  useGetCompanyLocationsQuery,
  useGetCompanyOrdersByFoodReportQuery,
} from '@durma-soft/gros-sdk';

import TableSizeSyncProvider from '@/providers/TableSizeSyncProvider';
import { useDatePickerRangeState } from '@/hooks/use-date-picker-range-state';

import { PrintButton } from '@/components/shared/print-button';
import { Skeleton } from '@/components/shared/shadcn-ui/skeleton';
import { DataTable, DataTableHTML } from '@/components/shared/data-table';
import { DatePickerWithRange } from '@/components/shared/date-picker-with-range';
import { SelectSingleCompanyLocations } from '@/components/shared/select-single-company-locations';

import { columns } from '@/config/company/reports/orders-by-food';

import print from '@/lib/print/print';

import { repeat } from '@/utils/helpers';
import { LastUpdatedInfo } from '@/components/shared/last-updated-info';

const CompanyOrdersByFoodReport = () => {
  const { t } = useTranslation();
  const memoizedColumns = useMemo(() => columns(t), [t]);

  const [locations, setLocations] = useState<ShortCompanyLocation[]>([]);

  const onUpdateLocations = (newLocations: ShortCompanyLocation[]) => {
    setLocations(newLocations);
  };

  const { from, to, setDateRange } = useDatePickerRangeState();

  const company = useAuthUserCompany();

  const {
    data: companyLocations,
    isSuccess: isCompanyLocationsSuccess,
    isLoading: isCompanyLocationsLoading,
  } = useGetCompanyLocationsQuery({
    company_id: company.id,
  });

  const { data, isLoading, isSuccess, dataUpdatedAt, isRefetching, refetch } =
    useGetCompanyOrdersByFoodReportQuery({
      company_id: company.id,
      start_date: from,
      end_date: to,
      company_location_ids: locations.map(({ id }) => id),
    });

  const matchedLocations = useMemo(() => {
    if (!isSuccess) return [];
    if (!locations.length) return data;
    const selectedLocationIds = locations.map(({ id }) => id);
    return data?.filter(({ id }) => selectedLocationIds.includes(id));
  }, [data, isSuccess, locations]);

  const handleRefetch = async () => {
    await refetch();
  };

  console.log('here');

  return (
    <>
      <Helmet>
        <title>{t('reports.orders-by-food.title')}</title>
      </Helmet>
      <div className="flex items-center justify-between">
        <h1 className="text-lg font-semibold md:text-2xl">
          {t('reports.orders-by-food.title')}
        </h1>
        {(isCompanyLocationsLoading || isLoading) && (
          <Skeleton className="h-10 w-48" />
        )}
        {isCompanyLocationsSuccess && isSuccess && (
          <LastUpdatedInfo
            dataUpdatedAt={dataUpdatedAt}
            isRefetching={isRefetching}
            handleRefetch={handleRefetch}
          />
        )}
      </div>
      <div className="flex flex-col gap-3 my-5 lg:flex-row">
        <div className="flex-1 flex flex-col gap-3 lg:flex-row">
          <div className="self-start">
            <DatePickerWithRange
              date={{ from: new Date(from), to: new Date(to) }}
              setDate={([from, to]) => setDateRange({ from, to })}
            />
          </div>
          <div>
            <SelectSingleCompanyLocations
              locations={companyLocations || []}
              isLoading={isCompanyLocationsLoading}
              selectedLocations={locations}
              onUpdateLocations={onUpdateLocations}
            />
          </div>
        </div>
        <div className="justify-start lg:justify-end">
          <PrintButton
            onClick={() =>
              print({
                content: (
                  <div>
                    <div>
                      <div className="flex-between">
                        <h1>{t('reports.orders-by-food.title')} </h1>
                        <div className="flex-row">
                          <p>{t('common.data-updated-at')}</p>
                          <span>&nbsp;</span>
                          <p>
                            <span> {format(dataUpdatedAt, 'dd.MM.yyyy')}</span>
                            <span> {t('common.in')} </span>
                            <span> {format(dataUpdatedAt, 'HH:mm:ss')}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <TableSizeSyncProvider>
                      <div className="flex flex-col gap-10">
                        {data?.map(({ name, id, foods }, index) => (
                          <div
                            key={id}
                            className="flex flex-col gap-4 print-in-separate-page"
                          >
                            <p>
                              {format(from, 'dd.MM.yyyy')}
                              {!isEqual(from, to) &&
                                ` - ${format(to, 'dd.MM.yyyy')}`}
                            </p>
                            <h3 className="text-xl font-medium">{name}</h3>
                            <DataTableHTML
                              data={foods}
                              columns={memoizedColumns}
                              isSyncReference={index === 0}
                            />
                          </div>
                        ))}
                      </div>
                    </TableSizeSyncProvider>
                  </div>
                ),
              })
            }
          />
        </div>
      </div>
      <div className="flex flex-col">
        {isLoading && (
          <div className="flex flex-col gap-10">
            {repeat(5).map((index) => (
              <div key={index}>
                <Skeleton className="w-full rounded-md h-60" />
              </div>
            ))}
          </div>
        )}

        {!isLoading &&
          isSuccess &&
          data.length === 0 &&
          locations.length === 0 && (
            <p className="text-center">{t('common.choose-location')}</p>
          )}

        {!isLoading &&
          isSuccess &&
          data.length === 0 &&
          locations.length !== 0 && (
            <p className="text-center">{t('common.no-results')}</p>
          )}

        {isSuccess && !isLoading && (
          <TableSizeSyncProvider>
            <div className="flex flex-col gap-10">
              {matchedLocations.map(({ name, id, foods }) => (
                <div
                  key={id}
                  className="flex flex-col gap-4 print-in-separate-page"
                >
                  <h3 className="text-xl font-medium">{name}</h3>
                  <DataTable data={foods} columns={memoizedColumns} />
                </div>
              ))}
            </div>
          </TableSizeSyncProvider>
        )}
      </div>
    </>
  );
};

export default CompanyOrdersByFoodReport;
