import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate, useParams } from 'react-router-dom';

import { Modal } from '@/components/shared/modal';
import { SubtractRestaurantFoodForm } from './subtract-restaurant-food-form';

export const SubtractRestaurantFoodModal = () => {
  const { t } = useTranslation();
  const { foodId } = useParams();
  const navigate = useNavigate();

  if (!foodId) {
    throw new Error('FoodId is required for subtracting food');
  }

  const closeModal = () => navigate('/restaurant/foods', { replace: true });

  const isModalOpen = Boolean(
    useMatch({ path: '/restaurant/foods/subtract/:foodId' }),
  );
  return (
    <Modal
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      title={t('food.subtract-from-inventory')}
      className="overflow-hidden flex flex-col"
    >
      <SubtractRestaurantFoodForm onClose={closeModal} foodId={+foodId} />
    </Modal>
  );
};
