import { format } from 'date-fns';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useMemo, useState, useTransition } from 'react';
import {
  commandScore,
  RESTAURANT_USER_ROLE,
  useAuthUserRestaurant,
  useGetRestaurantIngredientsQuery,
} from '@durma-soft/gros-sdk';
import { useMatch, useParams } from 'react-router-dom';

import { Link } from '@/components/shared/link';
import { DatePicker } from '@/components/shared/date-picker';
import { SearchInput } from '@/components/shared/search-input';
import { PrintButton } from '@/components/shared/print-button';
import { Skeleton } from '@/components/shared/shadcn-ui/skeleton';
import { buttonVariants } from '@/components/shared/shadcn-ui/button';
import { LastUpdatedInfo } from '@/components/shared/last-updated-info';
import { DataTable, DataTableHTML } from '@/components/shared/data-table';
import { EditRestaurantIngredientModal } from '@/components/restaurant/ingredients/edit-restaurant-ingredient-modal';
import { CreateRestaurantIngredientModal } from '@/components/restaurant/ingredients/create-restaurant-ingredient-modal';
import { EditRestaurantIngredientQuantityModal } from '@/components/restaurant/ingredients/edit-restaurant-ingredient-quantity-modal';

import { columns } from '@/config/restaurant/ingredients';

import print from '@/lib/print/print';

const RestaurantIngredients = () => {
  const { t } = useTranslation();
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  const restaurant = useAuthUserRestaurant();
  const hasEditPermission = restaurant.role !== RESTAURANT_USER_ROLE.EMPLOYEE;

  const { ingredientId } = useParams();
  const memoizedColumns = useMemo(() => {
    return columns(t);
  }, [t]);

  const [date, setDate] = useState(format(Date.now(), 'yyyy-MM-dd'));

  const [searchInput, setSearchInput] = useState('');
  const [, startTransition] = useTransition();

  const { data, isLoading, isSuccess, dataUpdatedAt, isRefetching, refetch } =
    useGetRestaurantIngredientsQuery({
      date,
      restaurant_id: restaurant.id,
    });

  const sortedData = useMemo(() => {
    return (data ? [...data] : [])
      .filter((element) => commandScore(element.name, searchInput) > 0)
      .sort((a, b) => {
        return (
          commandScore(b.name, searchInput) - commandScore(a.name, searchInput)
        );
      });
  }, [data, searchInput]);

  const handleRefetch = async () => {
    await refetch();
  };

  return (
    <>
      <Helmet>
        <title>{t('ingredient.title')}</title>
      </Helmet>
      <div>
        <div className="flex items-center justify-between">
          <h1 className="text-lg font-semibold md:text-2xl">
            {t('ingredient.title')}
          </h1>
          {isLoading && <Skeleton className="h-10 w-52" />}
          {isSuccess && (
            <LastUpdatedInfo
              dataUpdatedAt={dataUpdatedAt}
              isRefetching={isRefetching}
              handleRefetch={handleRefetch}
            />
          )}
        </div>
        <div className="flex flex-col gap-3 lg:flex-row justify-between mt-5 mb-12">
          <div>
            <DatePicker
              open={datePickerOpen}
              onOpenChange={setDatePickerOpen}
              date={new Date(date)}
              setDate={(newDate) => {
                if (!newDate) return;
                setDate(format(newDate, 'yyyy-MM-dd'));
                setDatePickerOpen(false);
              }}
            />
          </div>
          <div className="flex gap-4">
            <PrintButton
              variant="outline"
              onClick={() =>
                print({
                  content: (
                    <div>
                      <div className="flex-between">
                        <h1>
                          {`${t('ingredient.for-day')}:`}{' '}
                          {format(date, 'dd.MM.yyyy')}
                        </h1>
                        <div className="flex-row">
                          <p>{t('common.data-updated-at')}</p>
                          <span>&nbsp;</span>
                          <p>
                            <span>{format(dataUpdatedAt, 'dd.MM.yyyy')}</span>
                            <span> {t('common.in')} </span>
                            <span>{format(dataUpdatedAt, 'HH:mm:ss')}</span>
                          </p>
                        </div>
                      </div>
                      <DataTableHTML
                        columns={memoizedColumns}
                        data={sortedData}
                        isLoading={isLoading}
                        columnVisibility={{
                          edit: false,
                        }}
                      />
                    </div>
                  ),
                })
              }
            />
            {restaurant.role === RESTAURANT_USER_ROLE.OWNER && (
              <Link
                to="/restaurant/ingredients/create"
                className={buttonVariants({ variant: 'default' })}
              >
                {t('ingredient.create')}
              </Link>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <SearchInput
            className="self-start p-0"
            placeholder={t('common.search-placeholder')}
            searchInput={searchInput}
            handleChange={(event) => {
              startTransition(() => {
                setSearchInput(event.target.value);
              });
            }}
          />
          <DataTable
            columns={memoizedColumns}
            data={sortedData}
            isLoading={isLoading}
            columnVisibility={{
              edit: hasEditPermission,
            }}
          />
        </div>
      </div>
      <CreateRestaurantIngredientModal />

      {Boolean(
        useMatch({ path: '/restaurant/ingredients/edit/:ingredientId' }),
      ) && <EditRestaurantIngredientModal key={ingredientId} />}

      {Boolean(
        useMatch({
          path: '/restaurant/ingredients/edit-quantity/:ingredientId',
        }),
      ) && (
        <EditRestaurantIngredientQuantityModal
          key={ingredientId}
          date={date}
          setDate={setDate}
        />
      )}
    </>
  );
};

export default RestaurantIngredients;
