import { toast } from 'sonner';
import { Info } from 'lucide-react';
import { useId, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useAuthUserRestaurant,
  useCreateRestaurantTrackSubstituteOrderMutation,
  useGetRestaurantTrackSubstituteMealsQuery,
} from '@durma-soft/gros-sdk';
import { RestaurantShift } from '@durma-soft/gros-sdk/dist/types/restaurants';

import { noop, repeat } from '@/utils/helpers';

import { Alert } from '@/components/shared/alert';
import { CardDescription } from '@/components/shared/shadcn-ui/card';
import { Skeleton } from '@/components/shared/shadcn-ui/skeleton';
import { Label } from '@/components/shared/shadcn-ui/label';
import { Button } from '@/components/shared/shadcn-ui/button';
import {
  RadioGroup,
  RadioGroupItem,
} from '@/components/shared/shadcn-ui/radio-group';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/shared/shadcn-ui/tooltip';

interface ChooseSubstituteMealProps {
  date: string;
  userId: number;
  shift: RestaurantShift;
  restaurantTrackLocationId: number;
  onOrderCreated?: () => void;
}

export const ChooseSubstituteMeal = ({
  date,
  userId,
  restaurantTrackLocationId,
  shift,
  onOrderCreated = noop,
}: ChooseSubstituteMealProps) => {
  const radioId = useId();
  const { t } = useTranslation();
  const { id: restaurant_id } = useAuthUserRestaurant();
  const [selectedFoodId, setSelectedFoodId] = useState<string | null>(null);

  const { data, isSuccess, isLoading, isError, refetch, isRefetching } =
    useGetRestaurantTrackSubstituteMealsQuery({
      restaurant_id,
      shift,
      restaurant_track_location_id: restaurantTrackLocationId,
      date,
    });

  const createSubstituteOrderMutation =
    useCreateRestaurantTrackSubstituteOrderMutation();

  const handleCreateSubstituteOrder = async () => {
    if (createSubstituteOrderMutation.isPending || !selectedFoodId) return;
    try {
      await createSubstituteOrderMutation.mutateAsync({
        restaurant_id,
        user_id: userId,
        food_id: +selectedFoodId,
        shift,
        date,
        restaurant_track_location_id: restaurantTrackLocationId,
      });
      toast.success(t('track.no-order.order-created-success-msg'));
      onOrderCreated();
    } catch (error) {
      toast.error(t('track.no-order.order-created-error-msg'));
    }
  };

  return (
    <div>
      {isSuccess && data.length !== 0 && (
        <CardDescription className="xl:text-xl flex gap-1 items-center">
          <span>{t('track.no-order.substitute-meal')}</span>
          <TooltipProvider>
            <Tooltip delayDuration={100}>
              <TooltipTrigger>
                <Info className="h-4 w-4 text-muted-foreground" />
              </TooltipTrigger>
              <TooltipContent>
                <p>{t('track.no-order.substitute-meals-count-format')}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </CardDescription>
      )}
      {isLoading && (
        <div className="flex flex-col gap-3">
          {repeat(3).map((_, i) => (
            <Skeleton key={i} className="w-full h-4" />
          ))}
          <Skeleton className="w-1/3 h-8 ml-auto" />
        </div>
      )}
      {isError && (
        <>
          <Alert
            variant="destructive"
            title={t('track.no-order.substitute-meal-error-msg')}
            description={t('track.no-order.substitute-meal-error-msg-extended')}
            className="xl:[&>h5]:text-4xl xl:[&>div]:text-2xl xl:max-w-3xl xl:[&>svg]:w-10 xl:[&>svg]:h-10 xl:[&>svg~*]:pl-14"
          />
          <Button
            isLoading={isRefetching}
            onClick={() => refetch()}
            className="xl:h-14 xl:text-2xl xl:px-8"
          >
            {t('common.try-later')}
          </Button>
        </>
      )}
      {isSuccess && data.length === 0 && (
        <CardDescription className="xl:text-2xl">
          {t('track.no-order.no-substitute-meals')}
        </CardDescription>
      )}
      {isSuccess && data.length > 0 && (
        <div className="space-y-5 mt-2">
          <RadioGroup
            id={radioId}
            value={selectedFoodId || ''}
            onValueChange={(value) => setSelectedFoodId(value)}
            className="grid-cols-2 gap-y-3 gap-x-2"
          >
            {data.map(({ id, name }) => (
              <div
                key={id}
                className="flex items-start w-full space-x-2 xl:space-x-4"
              >
                <RadioGroupItem value={`${id}`} id={`${radioId}-${id}`} />
                <Label
                  htmlFor={`${radioId}-${id}`}
                  className="text-base xl:text-lg flex-1"
                >
                  {name}
                </Label>
              </div>
            ))}
          </RadioGroup>
          <Button
            onClick={handleCreateSubstituteOrder}
            isLoading={createSubstituteOrderMutation.isPending}
            disabled={!selectedFoodId}
            className="xl:h-14 text-lg xl:text-xl xl:px-8"
          >
            {t('track.no-order.create-order')}
          </Button>
        </div>
      )}
    </div>
  );
};
