import { ColumnDef, VisibilityState } from '@tanstack/react-table';

import { TType } from '@/types/general';
// to move
import { RestaurantFood } from '@durma-soft/gros-sdk/dist/types/restaurants';
export interface OrdersForPackingFoodReportRow
  extends Pick<RestaurantFood, 'id' | 'name'> {
  shift_1: number;
  shift_2: number;
  shift_3: number;
}

const sumShifts = (
  row: OrdersForPackingFoodReportRow,
  columnVisibility: VisibilityState,
) => {
  let total = 0;

  if (columnVisibility.shift_1) {
    total += row.shift_1;
  }

  if (columnVisibility.shift_2) {
    total += row.shift_2;
  }

  if (columnVisibility.shift_3) {
    total += row.shift_3;
  }

  return total;
};

export const columns = (
  t: TType,
): ColumnDef<OrdersForPackingFoodReportRow>[] => [
  {
    header: t('common.order-number'),
    cell: ({ row: { index } }) => <>{Number(index + 1)}</>,
  },
  {
    accessorKey: 'name',
    header: t('common.name'),
  },
  {
    accessorKey: 'shift_1',
    header: t('shifts.first'),
    footer: ({ table }) => {
      const { rows } = table.getFilteredRowModel();
      if (rows.length === 0) return 0;
      return rows.reduce((total, row) => total + row.original.shift_1, 0);
    },
  },
  {
    accessorKey: 'shift_2',
    header: t('shifts.second'),
    footer: ({ table }) => {
      const { rows } = table.getFilteredRowModel();
      if (rows.length === 0) return 0;
      return rows.reduce((total, row) => total + row.original.shift_2, 0);
    },
  },
  {
    accessorKey: 'shift_3',
    header: t('shifts.third'),
    footer: ({ table }) => {
      const { rows } = table.getFilteredRowModel();
      if (rows.length === 0) return 0;
      return rows.reduce((total, row) => total + row.original.shift_3, 0);
    },
  },
  {
    accessorKey: 'packages_total',
    header: t('common.total'),
    cell: (cellData) => {
      const { columnVisibility } = cellData.table.getState();
      const totalCount = sumShifts(cellData.row.original, columnVisibility);
      const formattedCount = Math.round(totalCount * 100) / 100;
      return formattedCount;
    },
    footer: ({ table }) => {
      const { rows } = table.getFilteredRowModel();
      const { columnVisibility } = table.getState();

      if (rows.length === 0) return 0;
      return rows.reduce(
        (total, row) => total + sumShifts(row.original, columnVisibility),
        0,
      );
    },
  },
];
