import { format } from 'date-fns';
import { RotateCw } from 'lucide-react';

import { Button } from '@/components/shared/shadcn-ui/button';

import { cn } from '@/utils/helpers';
import { useTranslation } from 'react-i18next';

interface LastUpdatedInfoProps {
  isRefetching: boolean;
  dataUpdatedAt: number;
  handleRefetch: () => void;
}

export const LastUpdatedInfo = ({
  isRefetching,
  dataUpdatedAt,
  handleRefetch,
}: LastUpdatedInfoProps) => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center justify-center gap-2">
      <Button
        className="px-3"
        variant="outline"
        disabled={isRefetching}
        onClick={handleRefetch}
      >
        <RotateCw
          className={cn(
            'text-foreground size-5',
            isRefetching && 'animate-spin',
          )}
        />
      </Button>
      <div className="flex flex-col justify-center gap-1">
        <p className="text-muted-foreground text-sm/none">
          {t('common.data-updated-at')}
        </p>
        <p className="text-sm/none">
          <span className="text-foreground text-sm/none font-medium">
            {format(dataUpdatedAt, 'dd.MM.yyyy')}
          </span>
          <span> {t('common.in')} </span>
          <span className="text-foreground text-sm/none font-medium tabular-nums">
            {format(dataUpdatedAt, 'HH:mm:ss')}
          </span>
        </p>
      </div>
    </div>
  );
};
