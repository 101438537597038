import { ReactNode } from 'react';
import { renderToString } from 'react-dom/server';

interface PrintProps {
  printWindow?: Window;
  title?: string;
  content?: ReactNode;
}

const print = (
  { printWindow: printWindowParam, content, title }: PrintProps = {
    printWindow: undefined,
    content: null,
    title: undefined,
  },
) => {
  const printWindow = printWindowParam || window.open('', '_blank');
  if (!printWindow) {
    throw new Error('Failed to open print window');
  }

  const contentString = renderToString(content);

  const html = `
    <html>
      ${title ? `<title>${title}</title>` : ''}
      <head>
        <style>
          * {
            box-sizing: border-box;
          }

          html {
            font-family: sans-serif;
          }

          ul {
            display: flex;
            flex-direction: column;
            gap: 0.375rem;
          }

          h1 {
            font-size: 1.5rem;
            font-weight: 600;
            margin-bottom: 1rem;
          }

          li {
            font-weight: 500;
            font-size: 1.125rem;
          }

          table {
            width: 100%;
            border-collapse: collapse;
          }

          th {
            font-weight: 600;
          }

          td, th {
            font-size: 0.75rem;
            border: 1px solid #ccc;
            padding: 0.25rem 0.5rem;
          }

          tfoot {
            font-weight: 500;
          }

          tfoot td {
            font-weight: 600;
          }

          th, td, tr {
            vertical-align: middle;
            text-align: left;
          }

          .print-in-separate-page {
            page-break-after: always;
          }

          .print-in-separate-page:last-of-type {
            page-break-after: auto;
          }

          .indent-cell {
            padding-left: 0.5rem; 
          }
          
          .bold-text-cell {
            font-weight: 600;
          }

          .flex-between {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .flex-row {
            display: flex;
          }

          @page {
            margin: 1.5cm;
          }
        </style>
      </head>
      <body>
        <main>
          ${contentString}
        </main>
      </body>
    </html>
  `;

  printWindow.document.write(html);
  printWindow.print();
  printWindow.close();
};

export default print;
