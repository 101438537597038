import { toast } from 'sonner';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { FormEvent, useEffect, useId, useMemo, useRef, useState } from 'react';
import {
  useAuthUserRestaurant,
  useSubtractFoodFromInventoryMutation,
  ValidationError,
} from '@durma-soft/gros-sdk';

import { useDatePickerState } from '@/hooks/use-date-picker-state';

import { Input } from '@/components/shared/shadcn-ui/input';
import { Label } from '@/components/shared/shadcn-ui/label';
import { DatePicker } from '@/components/shared/date-picker';
import { Button } from '@/components/shared/shadcn-ui/button';
import { DialogFooter } from '@/components/shared/shadcn-ui/dialog';

interface SubtractRestaurantFoodForm {
  quantity: number | null;
}

interface SubtractRestaurantFoodFormProps {
  foodId: number;
  onClose: () => unknown;
}

export const SubtractRestaurantFoodForm = ({
  foodId,
  onClose,
}: SubtractRestaurantFoodFormProps) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<SubtractRestaurantFoodForm>({
    quantity: 1,
  });

  const { date, setDate, datePickerOpen, setDatePickerOpen } =
    useDatePickerState({
      storeInUrl: false,
    });

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!inputRef.current) return;
    const handler = (e: MouseEvent) => {
      e.preventDefault();
      return false;
    };

    const input = inputRef.current;
    input.addEventListener('wheel', handler, { passive: false });
    return () => {
      input.removeEventListener('wheel', handler);
    };
  }, []);

  const uid = useId();
  const restaurant = useAuthUserRestaurant();
  const subtractFoodFromInventory = useSubtractFoodFromInventoryMutation();

  const isValid = useMemo(() => {
    return formData.quantity != null && formData.quantity >= 1;
  }, [formData]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isValid) return;
    try {
      await subtractFoodFromInventory.mutateAsync({
        restaurant_id: restaurant.id,
        food_id: foodId,
        date,
        quantity: formData.quantity!,
      });
      onClose();
      toast.success(t('food.subtract-success-msg'));
    } catch (error) {
      if (error instanceof ValidationError) {
        return toast.error(error.message);
      }
      toast.error(t('food.subtract-error-msg'));
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="grid gap-4 py-4">
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-quantity'}>{t('common.quantity')}</Label>
          <Input
            min={1}
            type="number"
            ref={inputRef}
            inputMode="numeric"
            value={formData.quantity || ''}
            onChange={(e) => {
              setFormData((prevState) => {
                return {
                  ...prevState,
                  quantity: e.target.value === '' ? null : +e.target.value,
                };
              });
            }}
            style={{
              appearance: 'textfield',
            }}
            className="flex-1 lowercase border-r-0 rounded-r-none"
          />
        </div>
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-date'}>{t('common.date')}</Label>
          <DatePicker
            open={datePickerOpen}
            onOpenChange={setDatePickerOpen}
            date={new Date(date)}
            setDate={(newDate) => {
              if (!newDate) return;
              setDate(format(newDate, 'yyyy-MM-dd'));
              setDatePickerOpen(false);
            }}
          />
        </div>
      </div>
      <DialogFooter className="mt-2">
        <Button
          type="submit"
          isLoading={subtractFoodFromInventory.isPending}
          disabled={!isValid}
        >
          {t('food.subtract')}
        </Button>
      </DialogFooter>
    </form>
  );
};
