import { convertUnits, Unit } from '@durma-soft/gros-sdk';
import { ColumnDef, VisibilityState } from '@tanstack/react-table';
import { IngredientsReportRow } from '@durma-soft/gros-sdk/dist/types/reports';

import { TType } from '@/types/general';

const sumCols = (
  row: IngredientsReportRow,
  columnVisibility: VisibilityState,
) => {
  let total = 0;

  if (columnVisibility.shift_1) {
    total += row.shift_1;
  }

  if (columnVisibility.shift_2) {
    total += row.shift_2;
  }

  if (columnVisibility.shift_3) {
    total += row.shift_3;
  }

  if (columnVisibility.manual_subtraction) {
    total += row.manual_subtraction;
  }

  return total;
};
export const columns = (t: TType): ColumnDef<IngredientsReportRow>[] => [
  {
    header: t('common.order-number'),
    cell: ({ row: { index } }) => <>{Number(index + 1)}</>,
  },
  {
    accessorKey: 'name',
    header: t('common.name'),
  },
  {
    accessorKey: 'unit',
    header: t('common.measure-unit.label'),
    cell: (cellData) => (
      <>
        {t(
          `common.measure-unit-short.${
            cellData.row.original.preferred_display_unit ||
            cellData.row.original.unit
          }`,
        )}
      </>
    ),
  },
  {
    accessorKey: 'shift_1',
    header: t('shifts.first'),
    cell: ({ row: { original } }) => {
      const convertedValue = convertUnits({
        from: original.unit,
        to: (original.preferred_display_unit as Unit) ?? original.unit,
        quantity: original.shift_1,
      });

      return (
        <>
          {convertedValue}
          {t(
            `common.measure-unit-short.${
              original.preferred_display_unit || original.unit
            }`,
          )}
        </>
      );
    },
  },
  {
    accessorKey: 'shift_2',
    header: t('shifts.second'),
    cell: ({ row: { original } }) => {
      const convertedValue = convertUnits({
        from: original.unit,
        to: (original.preferred_display_unit as Unit) ?? original.unit,
        quantity: original.shift_2,
      });

      return (
        <>
          {convertedValue}
          {t(
            `common.measure-unit-short.${
              original.preferred_display_unit || original.unit
            }`,
          )}
        </>
      );
    },
  },
  {
    accessorKey: 'shift_3',
    header: t('shifts.third'),
    cell: ({ row: { original } }) => {
      const convertedValue = convertUnits({
        from: original.unit,
        to: (original.preferred_display_unit as Unit) ?? original.unit,
        quantity: original.shift_3,
      });
      return (
        <>
          {convertedValue}
          {t(
            `common.measure-unit-short.${
              original.preferred_display_unit || original.unit
            }`,
          )}
        </>
      );
    },
  },
  {
    accessorKey: 'manual_subtraction',
    header: t('ingredient.manual'),
    cell: ({ row: { original } }) => {
      const convertedValue = convertUnits({
        from: original.unit,
        to: (original.preferred_display_unit as Unit) ?? original.unit,
        quantity: original.manual_subtraction,
      });
      return (
        <>
          {convertedValue}
          {t(
            `common.measure-unit-short.${
              original.preferred_display_unit || original.unit
            }`,
          )}
        </>
      );
    },
  },
  {
    header: t('common.total'),
    cell: (cellData) => {
      const { unit, preferred_display_unit } = cellData.row.original;

      const { columnVisibility } = cellData.table.getState();

      const totalCount = sumCols(cellData.row.original, columnVisibility);

      const formattedCount = convertUnits({
        from: unit,
        to: (preferred_display_unit as Unit) ?? unit,
        quantity: totalCount,
      });

      return (
        <>
          {formattedCount}
          {preferred_display_unit
            ? t(`common.measure-unit-short.${preferred_display_unit}`)
            : t(`common.measure-unit-short.${unit}`)}
        </>
      );
    },
  },
];
